import React from 'react'
import { getImage } from 'gatsby-plugin-image'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import RealEstateBanner from '@components/global/banner/RealEstateBanner'
import Best10RealEstate from '@components/global/gallery/Best10RealEstate'
import Best10Features from '@components/global/product/Best10Features'
import { Closing } from '@components/pages/best-10-semi-custom'
import LCF08 from '@components/global/forms/LeadContactForm/LCF08'

import {
  pageData as page,
  bannerData,
  listData,
  featuresData,
  closingData,
} from '@src/data/Best10SemiCustom'
import useBest10SemiCustomMetadata from '@hooks/best-10-semi-custom-metadata'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './10BestSemiCustom.module.scss'

const Best10SemiCustomWebsites = () => {
  const {
    bannerBGDesktop,
    bannerBG,
    bannerImg,
    bannerImgPhone,
    listMarkLaptop,
    listMarkTablet,
    listMarkPhone,
    listMichelleLaptop,
    listMichelleTablet,
    listMichellePhone,
    listKilianLaptop,
    listKilianTablet,
    listKilianPhone,
    listAngelLaptop,
    listAngelTablet,
    listAngelPhone,
    listFerrettiLaptop,
    listFerrettiTablet,
    listFerrettiPhone,
    listShannaLaptop,
    listShannaTablet,
    listShannaPhone,
    listCityLaptop,
    listCityTablet,
    listCityPhone,
    listDngLaptop,
    listDngTablet,
    listDngPhone,
    listHotLaptop,
    listHotTablet,
    listHotPhone,
    listMatLaptop,
    listMatTablet,
    listMatPhone,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    featuresImg,
    featuresAccent,
    closingImgDesktop,
    closingImgTablet,
    closingImgPhone,
    lcfModelTablet,
    lcfModelDesktop,
  } = useBest10SemiCustomMetadata()

  const imgListData = [
    {
      laptop: listMarkLaptop,
      tablet: listMarkTablet,
      phone: listMarkPhone,
      identifier: logo1,
    },
    {
      laptop: listMichelleLaptop,
      tablet: listMichelleTablet,
      phone: listMichellePhone,
      identifier: logo2,
    },
    {
      laptop: listKilianLaptop,
      tablet: listKilianTablet,
      phone: listKilianPhone,
      identifier: logo3,
    },
    {
      laptop: listAngelLaptop,
      tablet: listAngelTablet,
      phone: listAngelPhone,
      identifier: logo4,
    },
    {
      laptop: listFerrettiLaptop,
      tablet: listFerrettiTablet,
      phone: listFerrettiPhone,
      identifier: logo5,
    },
    {
      laptop: listShannaLaptop,
      tablet: listShannaTablet,
      phone: listShannaPhone,
      identifier: logo6,
    },
    {
      laptop: listCityLaptop,
      tablet: listCityTablet,
      phone: listCityPhone,
      identifier: logo7,
    },
    {
      laptop: listDngLaptop,
      tablet: listDngTablet,
      phone: listDngPhone,
      identifier: logo8,
    },
    {
      laptop: listHotLaptop,
      tablet: listHotTablet,
      phone: listHotPhone,
      identifier: logo9,
    },
    {
      laptop: listMatLaptop,
      tablet: listMatTablet,
      phone: listMatPhone,
      identifier: logo10,
    },
  ]
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767

  return (
    <LayoutInnerPage
      hasWhiteTransparentNav
      noFloatingShareButtons
      wrapperClassName={cx.wrapper}
    >
      <Seo title={page.title} uri={page.uri} />
      <RealEstateBanner
        breadcrumbs={page.breadcrumbs}
        wrapperClassName={cx.banner}
        heading={bannerData.heading.text}
        subheading={bannerData.subheading.text}
        bg={extractImage(isDesktop ? bannerBGDesktop : bannerBG)}
        img={extractImage(isPhone ? bannerImgPhone : bannerImg)}
      />
      <Best10RealEstate
        hasIdentifier
        hasScrollLaptop
        wrapperClassName={cx.list}
        heading={listData.heading.text}
        subheading={listData.subheading.text}
        buttonText={listData.list.buttonText}
        items={listData.list.items}
        imgItems={imgListData}
        identifierLayout={listData.list.identifierLayout}
        identifierLink={listData.list.identifierLink}
        scrollLaptopBackcover={listData.list.scrollLaptopBackcover}
      />
      <Best10Features
        hasButton
        wrapperClassName={cx.features}
        heading={featuresData.heading.text}
        subheading={featuresData.subheading.text}
        description={featuresData.description.text}
        img={extractImage(featuresImg)}
        accent={extractImage(featuresAccent)}
        imgAlt={featuresData.imgAlt}
        bestItems={featuresData.best}
        highlightsItems={featuresData.highlights}
        buttonText={featuresData.buttonText}
        buttonLink={featuresData.buttonLink}
      />
      <Closing
        heading={closingData.heading}
        paragraph={closingData.paragraph}
        buttonText={closingData.button.text}
        buttonLink={closingData.button.link}
        img={extractImage(
          isDesktop
            ? closingImgDesktop
            : isPhone
            ? closingImgPhone
            : closingImgTablet
        )}
        alt={closingData.alt}
      />
      <LCF08
        model={getImage(isDesktop ? lcfModelDesktop : lcfModelTablet)}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Best10SemiCustomWebsites
