// extracted by mini-css-extract-plugin
export var accent = "Best10Features-module--accent--fa5d1";
export var best = "Best10Features-module--best--b61c1";
export var container = "Best10Features-module--container--62ecb";
export var darkText = "Best10Features-module--dark-text--2b050";
export var features = "Best10Features-module--features--ccb97";
export var header = "Best10Features-module--header--8d16f";
export var highlights = "Best10Features-module--highlights--42fc6";
export var img = "Best10Features-module--img--36d8e";
export var mid = "Best10Features-module--mid--b2a93";
export var modal = "Best10Features-module--modal--6bb80";
export var placeholder = "Best10Features-module--placeholder--0230f";
export var wrapper = "Best10Features-module--wrapper--09013";