import { useEffect, useState } from 'react'
import { debounce } from '@utils/debounce'

const isBrowser = typeof window !== 'undefined'

export const media = {
  portrait: 'screen and (orientation: portrait)',
  landscape: 'screen and (orientation: landscape)',
  mobile: 'screen and (hover:none)',
  desktopLg: 'screen and (min-width: 1280px)',
  desktop: 'screen and (min-width: 1024px)',
  minTablet: 'screen and (min-width: 768px)',
  tablet: 'screen and (min-width: 768px) and (max-width: 1023px)',
  phone: 'screen and (max-width: 767px)',
  phoneSm: 'screen and (max-width: 639px)',
}

const useMedia = (query, defaultState = false) => {
  const [state, setState] = useState(
    isBrowser ? () => window.matchMedia(query).matches : defaultState
  )

  useEffect(() => {
    let mounted = true
    const mql = window.matchMedia(query)
    const onChange = debounce(() => {
      if (!mounted) {
        return
      }
      setState(!!mql.matches)
    }, 50)

    if ('addListener' in mql) {
      mql.addListener(onChange)
    }

    if ('addEventListener' in mql) {
      mql.addEventListener('change', onChange)
    }

    setState(mql.matches)

    return () => {
      mounted = false

      if ('addListener' in mql) {
        mql.removeListener(onChange)
      }

      if ('addEventListener' in mql) {
        mql.removeEventListener('change', onChange)
      }
    }
  }, [query])

  return state
}

export default useMedia
