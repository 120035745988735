import * as yup from 'yup'
import 'yup-phone'
import { yupResolver } from '@hookform/resolvers/yup'

export const SignUpSchema = yup.object().shape({
  field_125474663: yup.string().required().label('First Name'),
  field_125474662: yup.string().required().label('Last Name'),
  field_125471274: yup.string().email().required().label('Email Address'),
  field_125474666: yup.string().phone().required().label('Phone'),
  field_125474669: yup.string(),
  automaton: yup.string(),
  field_126636982: yup.string().label('Lead Name'),
})

export const SignUpResolver = {
  schema: SignUpSchema,
  resolver: yupResolver(SignUpSchema),
}
