import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import FormFreeConsultation from '../../FreeConsultation'
import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './LCF08.module.scss'

const LeadContentForm08 = ({
  modelName,
  model,
  title,
  subtitle,
  submitLabel,
  leadName,
  layout = 'default',
  formLocation,
}) => {
  const layoutClassName = (value) => {
    if (value === 'alt') {
      return cx.layoutAlt
    } else if (value === 'B') {
      return cx.layoutB
    } else {
      return ''
    }
  }

  // console.log(modelName)

  return (
    <section
      className={clsx(cx.wrapper, layoutClassName(layout), 'lcf08-wrapper')}
    >
      <div className={clsx(cx.content, 'lcf08-content')}>
        <div className={clsx(cx.formContainer, 'lfc08-container')}>
          <h2>
            <span className="heading-3">{parse(title)}</span>
            <span className="subtitle-7">{parse(subtitle)}</span>
          </h2>

          <div className={clsx(cx.form, 'lfc08-form')}>
            <FormFreeConsultation
              wrapperClass={cx.form}
              submitLabel={submitLabel}
              leadName={submitLabel}
              isEmailPhoneMerger={true}
              isMessageTextarea={true}
              formLocation={formLocation}
            />
          </div>
        </div>
        <div className={clsx(cx.model, 'image-model lfc08-model', modelName)}>
          <ElementImageFile
            className={clsx(modelName === 'CutOutCassie' && cx.short)}
            src={model}
            alt={title}
            lazy={true}
          />
        </div>
      </div>
    </section>
  )
}

LeadContentForm08.propTypes = {
  modelName: PropTypes.string,
  model: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  submitLabel: PropTypes.string,
  leadName: PropTypes.string,
  layout: PropTypes.string,
  formLocation: PropTypes.string,
}

export default LeadContentForm08
