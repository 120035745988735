import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import * as cx from './Closing.module.scss'

const Closing = ({
  wrapperClassName,
  heading,
  paragraph,
  buttonText,
  buttonLink,
  img,
  alt,
  headingClassName = 'heading-3',
  paragraphClassName = 'default-body large',
}) => {
  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <div className={cx.screenshots}>
        <ElementImage src={img} alt={alt} />
      </div>
      <div className={cx.details}>
        <div className={cx.card}>
          <div className={cx.content}>
            <h4 className={headingClassName}>{heading}</h4>
            <p className={paragraphClassName}>{paragraph}</p>
            <Link to={buttonLink}>
              <Button text={buttonText} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

Closing.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  img: PropTypes.string,
  alt: PropTypes.string,
  headingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
}

export default Closing
