import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import axios from 'axios'

// Resolver
import { SignUpResolver } from '@src/resolvers/SignUp'

// Fields
import Input from './fields/Input'
import Textarea from './fields/Textarea'

const FormFreeConsultation = ({
  wrapperClass = '',
  submitLabel = 'Submit',
  isLabelHidden = false,
  labelClassName = '',
  isEmailPhoneMerger = false,
  leadName = 'Free Consulation',
  isMessageTextarea = false,
  buttonClassName = 'primary-button large',
  labelVersion = 'A',
  formLocation = '',
}) => {
  const [submitting, setSubmitting] = useState(false)

  const {
    register,
    resetField,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    // mode: 'onChange',
    resolver: SignUpResolver.resolver,
  })

  useEffect(() => {
    setValue('field_126636982', leadName)
  }, [])

  const onSubmit = async (data) => {
    setSubmitting(true)

    await axios
      .post(
        `${process.env.GATSBY_FORMSTACK_API}/${process.env.GATSBY_FORMSTACK_KEY}/4818068`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      )
      .then((res) => {
        alert('Successfully')
        reset()
      })
      .catch((err) => {
        alert('Error')
      })

    setSubmitting(false)
  }

  return (
    <form className={wrapperClass} onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-col">
          <Input
            register={register}
            label="First Name"
            name="field_125474663"
            id={`field_125474663_${formLocation}`}
            type="text"
            error={errors.field_125474663?.message}
            isRequired={true}
            isLabelHidden={isLabelHidden}
            labelClassName={labelClassName}
            aria-label="First Name"
            formLocation={formLocation}
          />
        </div>
        <div className="form-col">
          <Input
            register={register}
            label="Last Name"
            name="field_125474662"
            id={`field_125474662_${formLocation}`}
            type="text"
            error={errors.field_125474662?.message}
            isRequired={true}
            isLabelHidden={isLabelHidden}
            labelClassName={labelClassName}
            aria-label="Last Name"
            formLocation={formLocation}
          />
        </div>
      </div>

      {/* Email and Contact */}
      {isEmailPhoneMerger && (
        <>
          <div className="form-row">
            <div className="form-col">
              <Input
                register={register}
                label="Email Address" // Email Address
                name="field_125471274"
                id={`field_125471274_${formLocation}`}
                type="email"
                error={errors.field_125471274?.message}
                isRequired={true}
                isLabelHidden={isLabelHidden}
                labelClassName={labelClassName}
                aria-label="Email"
                formLocation={formLocation}
              />
            </div>
            <div className="form-col">
              <Input
                register={register}
                label="Phone Number"
                name="field_125474666"
                id={`field_125474666_${formLocation}`}
                type="text"
                error={errors.field_125474666?.message}
                isRequired={true}
                isLabelHidden={isLabelHidden}
                labelClassName={labelClassName}
                aria-label="Phone Number"
                formLocation={formLocation}
              />
            </div>
          </div>
        </>
      )}

      {!isEmailPhoneMerger && (
        <>
          <div className="form-row">
            <div className="form-col">
              <Input
                register={register}
                label="E-mail Address"
                name="field_125471274"
                id={`field_125471274_${formLocation}`}
                type="email"
                error={errors.field_125471274?.message}
                isRequired={true}
                isLabelHidden={isLabelHidden}
                labelClassName={labelClassName}
                aria-label="E-mail Address"
                formLocation={formLocation}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <Input
                register={register}
                label={labelVersion === 'A' ? 'Phone Number' : 'Contact Number'}
                name="field_125474666"
                id={`field_125474666_${formLocation}`}
                type="text"
                error={errors.field_125474666?.message}
                isRequired={true}
                isLabelHidden={isLabelHidden}
                labelClassName={labelClassName}
                aria-label="E-mail Address"
              />
            </div>
          </div>
        </>
      )}

      <div className="form-row">
        <div className="form-col">
          {isMessageTextarea && (
            <Textarea
              register={register}
              label={isEmailPhoneMerger ? 'Questions or Comments' : 'Message'}
              name="field_125474669"
              id={`field_125474669_${formLocation}`}
              error={errors.field_125474669?.message}
              isRequired={false}
              isLabelHidden={isLabelHidden}
              labelClassName={labelClassName}
              aria-label={
                isEmailPhoneMerger ? 'Questions or Comments' : 'Message'
              }
              formLocation={formLocation}
            />
          )}

          {isMessageTextarea === false && (
            <Input
              register={register}
              label={isEmailPhoneMerger ? 'Questions or Comments' : 'Message'}
              name="field_125474669"
              id={`field_125474669_${formLocation}`}
              type="text"
              error={errors.field_125474669?.message}
              isRequired={false}
              isLabelHidden={isLabelHidden}
              labelClassName={labelClassName}
              aria-label={
                isEmailPhoneMerger ? 'Questions or Comments' : 'Message'
              }
              formLocation={formLocation}
            />
          )}
        </div>
      </div>

      {/* Robot Verifier & Lead Name */}
      <div className="hidden">
        <Input
          register={register}
          label="Automaton"
          name="automaton"
          type="text"
          error={errors.automaton?.message}
          isRequired={false}
          aria-label="Automaton"
        />
        <Input
          register={register}
          label="Lead Name"
          name="field_126636982"
          type="text"
          error={errors.field_126636982?.message}
          isRequired={false}
          aria-label="Lead Name"
        />
      </div>

      <div className="form-row">
        <div className="form-col">
          <div className="form-control form-submit">
            {/* {submitting && <>Spinner</>} */}
            <button type="submit" className={buttonClassName}>
              {submitLabel}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

FormFreeConsultation.propTypes = {
  wrapperClass: PropTypes.string,
  submitLabel: PropTypes.string,
  isLabelHidden: PropTypes.bool,
  labelClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  leadName: PropTypes.string,
  isEmailPhoneMerger: PropTypes.bool,
  isMessageTextarea: PropTypes.bool,
  formLocation: PropTypes.string,
}

export default FormFreeConsultation
