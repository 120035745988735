import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { navigate } from 'gatsby'
import clsx from 'clsx'

import { Button } from '@components/global/buttons/Buttons'
import ElementImage from '@components/global/element/Image'
import CheckItems from '@components/global/CheckItems'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import * as cx from './Best10Features.module.scss'

const Best10Features = ({
  wrapperClassName,
  heading,
  subheading,
  description,
  leftTitle = 'Best for',
  rightTitle = 'Highlights',
  accent,
  img,
  imgAlt,
  bestItems,
  highlightsItems,
  hasDarkHeaderText,
  hasButton,
  hasModalButton,
  buttonText,
  buttonLink,
  headingClassName = 'heading-2',
  subheadingClassName = 'subtitle-7',
  descriptionClassName = 'subtitle-5',
  titleClassName = 'subtitle-1',
  itemClassName = 'default-body large',
  modalTitle = 'Sign Up Today For A <br>Free Consultation',
  modalDescription = 'See how Agent Image can help you grow your real estate business.',
  modalSubmit = 'Get Started Today',
  modalTemplate = 'm1',
}) => {
  const modalRef = useRef()

  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <ElementImage className={cx.accent} src={accent} alt="accent" />
      <div className={cx.container}>
        <h3 className={clsx([cx.header], { [cx.darkText]: hasDarkHeaderText })}>
          <span className={subheadingClassName}>{subheading}</span>
          <span className={headingClassName}>{parse(heading)}</span>
        </h3>
        <div className={cx.mid}>
          <div className={cx.placeholder}>
            <ElementImage className={cx.img} src={img} alt={imgAlt} />
          </div>
          <p className={descriptionClassName}>{parse(description)}</p>
        </div>
        <div className={cx.features}>
          <div className={cx.best}>
            <h5 className={titleClassName}>{leftTitle}</h5>
            <CheckItems items={bestItems} itemClass={itemClassName} />
          </div>
          <hr />
          <div className={cx.highlights}>
            <h5 className={titleClassName}>{rightTitle}</h5>
            <CheckItems items={highlightsItems} itemClass={itemClassName} />
          </div>
        </div>

        {hasButton && (
          <Button text={buttonText} onClick={() => navigate(buttonLink)} />
        )}
        {hasModalButton && (
          <>
            <Button
              text={buttonText}
              onClick={() => modalRef.current.openModal()}
            />
            <ModalForm
              wrapperClassName={cx.modal}
              childFunc={modalRef}
              title={modalTitle}
              description={modalDescription}
              template={modalTemplate}
            >
              <FormSignUp submitLabel={modalSubmit} />
            </ModalForm>
          </>
        )}
      </div>
    </section>
  )
}

Best10Features.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  accent: PropTypes.string,
  hasDarkHeaderText: PropTypes.bool,
  hasButton: PropTypes.bool,
  hasModalButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  img: PropTypes.string,
  imgAlt: PropTypes.string,
  bestItems: PropTypes.array,
  highlightsItems: PropTypes.array,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  modalSubmit: PropTypes.string,
  modalTemplate: PropTypes.string,
}

export default Best10Features
